/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useInterval } from "react-powerhooks";
import theme from "../theme.jsx";
// import './style.css'

import {
  CircularProgress,
  TextField,
  Button,
  Paper,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

const hrefStyle = {
  color: "white",
  textDecoration: "none",
};

const bodyDivStyle = {
  margin: "auto",
  marginTop: 150,
  width: "500px",
  display: "flex",
  height: "100vh",
  backgroundColor: "#212121",
};

const useStyles = makeStyles({
  notchedOutline: {
    // borderColor: "#f85a3e !important",
  },
});

const LoginDialog = (props) => {
  const {
    globalUrl,
    isLoaded,
    isLoggedIn,
    setIsLoggedIn,
    removeCookie,
    setCookie,
    register,
    checkLogin,
  } = props;

  let navigate = useNavigate();
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstRequest, setFirstRequest] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginViewLoading, setLoginViewLoading] = useState(false);
  const [ssoUrl, setSSOUrl] = useState("");

  const [MFAField, setMFAField] = useState(false);
  const [MFAValue, setMFAValue] = useState("");

  // Used to swap from login to register. True = login, false = register

  useEffect(() => {
    checkAdmin();
  }, [loginViewLoading]);

  // Error messages etc
  const [loginInfo, setLoginInfo] = useState("");

  const handleValidateForm = () => {
    return username.length > 1 && password.length > 1;
  };

  if (isLoggedIn === true) {
    //window.location.pathname = "/workflows";
    navigate("/workflows");
  }

  const removeCookies = () => {
    console.log("remove cookies called---->")
    const cookiesToRemove = ["session_token", "session_token", "__session"];
    cookiesToRemove.forEach((cookie) => {
      document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/api/v1;`;
      document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
    });
  };

  const checkAdmin = () => {
    const url = globalUrl + "/api/v1/checkusers";
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) =>
        response.json().then((responseJson) => {
          console.log("my checkuser response------>", responseJson)
          if (responseJson["success"] === false) {
            setLoginInfo(responseJson["reason"]);
            removeCookies();
          } else {
            if (
              responseJson.sso_url !== undefined &&
              responseJson.sso_url !== null
            ) {
              setSSOUrl(responseJson.sso_url);
            }

            if (loginViewLoading) {
              setLoginViewLoading(false);
              checkLogin();
              stop();

              if (
                responseJson.reason !== undefined &&
                responseJson.reason !== null
              ) {
                setLoginInfo(responseJson.reason);
              }
            }

            if (responseJson.reason === "stay") {
              navigate("/adminsetup");
            }
          }
        })
      )
      .catch((error) => {
        if (!loginViewLoading) {
          setLoginViewLoading(true);
          start();
        }
      });
  };

  const { start, stop } = useInterval({
    duration: 3000,
    startImmediate: false,
    callback: () => {
      checkAdmin();
    },
  });

  if (firstRequest) {
    setFirstRequest(false);
    checkAdmin();
  }

  const onSubmit = (e) => {
    setLoginLoading(true);
    e.preventDefault();
    setLoginInfo("");
    // FIXME - add some check here ROFL

    // Just use this one?
    var data = { username: username, password: password };
    if (MFAValue !== undefined && MFAValue !== null && MFAValue.length > 0) {
      data["mfa_code"] = MFAValue;
    }

    var baseurl = globalUrl;
    if (register) {
      var url = baseurl + "/api/v1/login";
      fetch(url, {
        mode: "cors",
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
        crossDomain: true,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((response) =>
          response.json().then((responseJson) => {
            setLoginLoading(false);
            if (responseJson["success"] === false) {
              setLoginInfo(responseJson["reason"]);
            } else {
              if (responseJson["reason"] === "MFA_REDIRECT") {
                setLoginInfo(
                  "MFA required. Please the 6-digit code from your authenticator"
                );
                setMFAField(true);
                return;
              }

              setLoginInfo("Successful login, rerouting");
              for (var key in responseJson["cookies"]) {
                setCookie(
                  responseJson["cookies"][key].key,
                  responseJson["cookies"][key].value,
                  { path: "/" }
                );
              }

              if (
                responseJson.tutorials === undefined ||
                responseJson.tutorials === null ||
                !responseJson.tutorials.includes("welcome")
              ) {
                console.log("RUN Welcome!!");
                window.location.pathname = "/welcome";
                return;
              }

              const tmpView = new URLSearchParams(window.location.search).get(
                "view"
              );
              if (tmpView !== undefined && tmpView !== null) {
                //const newUrl = `/${tmpView}${decodeURIComponent(window.location.search)}`
                const newUrl = `/${tmpView}`;
                window.location.pathname = newUrl;
              } else {
                window.location.pathname = "/workflows";
              }

              setIsLoggedIn(true);
            }
          })
        )
        .catch((error) => {
          setLoginLoading(false);
          setLoginInfo("Error logging in: " + error);
        });
    } else {
      url = baseurl + "/api/v1/users/register";
      fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) =>
          response.json().then((responseJson) => {
            if (responseJson["success"] === false) {
              setLoginInfo(responseJson["reason"]);
            } else {
              setLoginInfo("Successful register!");
            }
          })
        )
        .catch((error) => {
          setLoginInfo("Error in from backend: ", error);
        });
    }
  };

  const onChangeUser = (e) => {
    setUsername(e.target.value);
  };

  const onChangePass = (e) => {
    setPassword(e.target.value);
  };

  //const onClickRegister = () => {
  //	if (props.location.pathname === "/login") {
  //		window.location.pathname = "/register"
  //	} else {
  //		window.location.pathname = "/login"
  //	}

  //	setLoginCheck(!register)
  //}

  //var loginChange = register ? (<div><p onClick={setLoginCheck(false)}>Want to register? Click here.</p></div>) : (<div><p onClick={setLoginCheck(true)}>Go back to login? Click here.</p></div>);
  var formtitle = register ? <div>Login</div> : <div>Register</div>;
  const imgsize = 100;
  const basedata = (
    <div>
      <style jsx global>{`
        .css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
          -webkit-box-shadow: none !important; /* Removes the shadow */
          -webkit-text-fill-color: black !important; /* Set text color to black for visibility */
          caret-color: black !important; /* Set caret color to black */
          border-radius: inherit !important;
        }
      `}</style>

      <Paper
        style={{
          // paddingLeft: "30px",
          // paddingRight: "30px",
          // paddingBottom: "30px",
          // paddingTop: "30px",
          position: "relative",
          backgroundColor: "#212121",
        }}
      >
        {/* <div
          style={{
            position: "absolute",
            top: -imgsize / 2 - 10,
            left: 250 - imgsize / 2,
            height: imgsize,
            width: imgsize,
          }}
        >
          <img
            src="images/argus1.png"
            style={{
              height: imgsize + 10,
              width: imgsize + 10,
              border: "2px solid rgba(255,255,255,0.6)",
              borderRadius: imgsize,
            }}
          />
        </div> */}

        {loginViewLoading ? (
          <div style={{ textAlign: "center", marginTop: 50 }}>
            <Typography
              variant="body2"
              style={{ marginBottom: 20, color: "white" }}
            >
              Waiting for the Argus SOAR database to become available. This may
              take up to a minute.
            </Typography>

            {loginInfo === undefined ||
            loginInfo === null ||
            loginInfo.length === 0 ? null : (
              <div style={{ marginTop: "10px" }}>
                Database Response: {loginInfo}
              </div>
            )}
            <CircularProgress color="secondary" style={{ color: "white" }} />

            <Paper
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "30px",
                paddingTop: "30px",
                position: "relative",
                textAlign: "left",
                marginTop: 15,
              }}
            >
              <Typography
                variant="body2"
                style={{ marginBottom: 20, color: "white" }}
              >
                <b>
                  Are you sure Argus SOAR is{" "}
                  <a
                    rel="norefferer"
                    target="_blank"
                    href="https://github.com/frikky/Shuffle/blob/master/.github/install-guide.md"
                    style={{ textDecoration: "none", color: "#f86a3e" }}
                  >
                    installed correctly
                  </a>
                  ?
                </b>
              </Typography>
              <Typography
                variant="body2"
                style={{ marginBottom: 20, color: "white" }}
              >
                <b>1.</b> Make sure Argus SOAR-database folder has correct
                access, and that you have a minimum of{" "}
                <b>2Gb of RAM available</b>: <br />
                <br />
                sudo chown -R 1000:1000 Argus SOAR-database
              </Typography>
              <Typography
                variant="body2"
                style={{ marginBottom: 20, color: "white" }}
              >
                <b>2.</b> Disable memory swap on the host:
                <br />
                <br />
                sudo swapoff -a
              </Typography>
              <Typography
                variant="body2"
                style={{ marginBottom: 20, color: "white" }}
              >
                <b>3</b>. Restart the database:
                <br />
                <br />
                sudo docker restart Argus SOAR-opensearch
              </Typography>
            </Paper>
            <Typography
              variant="body2"
              style={{ marginBottom: 10, color: "white", marginTop: 20 }}
            >
              Need help?{" "}
              <a
                rel="norefferer"
                target="_blank"
                href="https://discord.gg/B2CBzUm"
                style={{ textDecoration: "none", color: "#f86a3e" }}
              >
                Join the Discord!
              </a>
            </Typography>
          </div>
        ) : (
          <div style={{ display: "flex", height: "100vh" }}>
            <div
              style={{
                flex: 2,
                display: "flex",
                alignItems: "center",
                padding: "40px",
                backgroundColor: "#212121",
                position: "relative",
              }}
            >
              {/* Top Left SVG */}
              <div>
                <img
                  src={"/images/logos/leftSide.svg"}
                  alt="Top Left Decoration"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "120px",
                    height: "auto",
                  }}
                />
              </div>

              {/* Content in the center */}
              <div
                style={{
                  textAlign: "left",
                  color: "white",
                  maxWidth: "60%",
                  marginLeft: "6vw",
                  marginRight: "6vw",
                }}
              >
                <img
                  src={"/images/logos/topleft_logo.svg"}
                  alt="Argus Soar logo"
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    verticalAlign: "middle",
                  }}
                />
                <h2
                  style={{
                    fontSize: "1.6em",
                    fontWeight: "500",
                    marginBottom: "10px",
                    width: "98%",
                    lineHeight: "1.15",
                    fontFamily: "'Lato', sans-serif",
                  }}
                >
                  Welcome Back! Sign In to Access Your Personalized Dashboard
                  and Continue Your Journey with Us
                </h2>
                <p
                  style={{
                    fontSize: "14px",
                    width: "95%",
                    fontWeight: "inherit",
                    lineHeight: "1.4",
                  }}
                >
                  Enter your credentials below to securely access your account,
                  explore new features, and stay connected with all the latest
                  updates tailored just for you.
                </p>
              </div>

              {/* Bottom Right SVG */}
              <div>
                <img
                  src="/images/logos/bottomSide.svg"
                  alt="Bottom Right Decoration"
                  style={{ position: "absolute", bottom: "0", right: "0" }}
                />
              </div>
            </div>

            {/* Right Half (Form) */}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "30px",
                backgroundImage: 'url("/images/logos/formImage.svg")',
                backgroundSize: "auto",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: "relative",
                boxShadow: "-1px 0px 7px 0px rgb(199 190 199)",
              }}
            >
              <form
                onSubmit={onSubmit}
                style={{ width: "100%", maxWidth: "300px" }}
              >
                <h2
                  style={{
                    marginBottom: "20px",
                    fontSize: "32px",
                    color: "#000",
                    fontWeight: "600",
                    fontFamily:
                      "'Source Sans 3', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                  }}
                >
                  Sign In
                </h2>
                <div>
                  <div style={{ color: "black", fontSize: "14px" }}>
                    Username
                  </div>
                  <TextField
                    color="primary"
                    className="inputbox"
                    style={{
                      backgroundColor: "#fff",
                      marginTop: 5,
                      borderRadius: "20px",
                      height: "35px",
                    }}
                    autoFocus
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                      style: {
                        height: "35px",
                        color: "black",
                        fontSize: "14px",
                        borderRadius: "20px",
                      },
                    }}
                    required
                    fullWidth={true}
                    autoComplete="username"
                    placeholder="Username"
                    id="emailfield"
                    margin="normal"
                    variant="outlined"
                    onChange={onChangeUser}
                  />
                </div>
                <div>
                  <div style={{ color: "black", fontSize: "14px" }}>
                    Password
                  </div>
                  <TextField
                    color="primary"
                    style={{
                      backgroundColor: "#fff",
                      marginTop: 5,
                      borderRadius: "20px",
                     height: "35px",
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline,
                      },
                      style: {
                        height: "35px",
                        color: "black",
                        fontSize: "14px",
                        borderRadius: "20px",
                        borderColor: "transparent",
                      },
                    }}
                    required
                    id="outlined-password-input"
                    fullWidth={true}
                    type="password"
                    autoComplete="current-password"
                    placeholder="Password"
                    margin="normal"
                    variant="outlined"
                    onChange={onChangePass}
                  />
                </div>
                {MFAField === true ? (
                  <div style={{ marginTop: 15 }}>
                    <TextField
                      color="primary"
                      style={{
                        backgroundColor: "#fff",
                        marginTop: 5,
                        borderRadius: "20px",
                        height: "35px",
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                        style: {
                          height: "35px",
                          color: "black",
                          fontSize: "1em",
                          borderRadius: "20px",
                          borderColor: "transparent",
                        },
                      }}
                      required
                      id="outlined-password-input"
                      fullWidth={true}
                      type="text"
                      placeholder="6-digit code"
                      margin="normal"
                      variant="outlined"
                      onChange={(event) => {
                        setMFAValue(event.target.value);
                      }}
                    />
                  </div>
                ) : null}
                <div style={{ display: "flex", marginTop: "15px" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    style={{
                      flex: "1",
                      backgroundColor: "#ff6600",
                      color: "#fff",
                      height: "40px", // Reduced size
                      borderRadius: "20px", // Rounded corners
                    }}
                    disabled={!handleValidateForm() || loginLoading}
                  >
                    {loginLoading ? (
                      <CircularProgress
                        color="secondary"
                        style={{ color: "white" }}
                      />
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      position: "absolute",
                      bottom: "4px",
                      padding: "0 20px",
                      fontSize: "11px",
                      color: "#666",
                      left: "0",
                    }}
                  >
                    <span>© Argus by Genix Cyber All Rights Reserved.</span>
                    <span>Version: 2024/Aug</span>
                  </div>
                </div>
                <div style={{ marginTop: "10px", color: "#f86a3e" }}>
                  {loginInfo}
                </div>
                {ssoUrl !== undefined &&
                ssoUrl !== null &&
                ssoUrl.length > 0 ? (
                  <div>
                    <Typography style={{ textAlign: "center", color: "#333" }}>
                      Or
                    </Typography>
                    <div style={{ textAlign: "center", margin: 10 }}>
                      <Button
                        fullWidth
                        id="sso_button"
                        color="secondary"
                        variant="outlined"
                        type="button"
                        style={{
                          flex: "1",
                          marginTop: 5,
                          color: "#ff6600",
                          borderColor: "#ff6600",
                          borderRadius: "20px",
                          height: "40px",
                        }}
                        onClick={() => {
                          window.location.href = ssoUrl;
                        }}
                      >
                        Use SSO
                      </Button>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );

  const loadedCheck = isLoaded ? <div>{basedata}</div> : <div></div>;

  useEffect(() => {
    setTimeout(() => {
      if (ssoUrl !== undefined && ssoUrl !== null && ssoUrl.length > 0) {
        //id="sso_button"
        const ssoBtn = document.getElementById("sso_button");
        if (ssoBtn !== undefined && ssoBtn !== null) {
          //console.log("SSO BTN: ", ssoBtn)
          const cursearch =
            typeof window === "undefined" || window.location === undefined
              ? ""
              : window.location.search;
          var tmpView = new URLSearchParams(cursearch).get("autologin");
          if (tmpView !== undefined && tmpView !== null) {
            if (tmpView === "true") {
              console.log("Tmp: ", tmpView);
              ssoBtn.click();
            }
          }
        }
      }
    }, 200);
  }, [ssoUrl]);

  return <div>{loadedCheck}</div>;
};

export default LoginDialog;
